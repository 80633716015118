import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p className="centered-text">
          Logic Lab 406 Coming Soon
        </p>
        <a href="https://pf.logiclab406.org" target="_blank" rel="noopener noreferrer" className="app-link">
          View our app "PhosFilter"
        </a>
      </header>
    </div>
  );
}

export default App;